<template>
    <form ref="formEl" class="sim-form" @submit.prevent="handleSubmit()">
        <slot v-bind="{ formData, formErrors, globalFormError, isFormSubmitting, isFieldOfValue, getFieldValue }" />
    </form>
</template>

<script lang="ts" setup generic="T extends ZodRawShape">
import type { ZodObject, ZodRawShape } from 'zod'
import { z } from 'zod'
import { type FormErrors, type FormEvent } from '@core-types/components/CoreUIForm'
import { type FormDataObject } from '@core/app/utils/zod'
import type { MaybeRef, Ref, UnwrapRef } from 'vue'
import type { MaybePromise } from 'rollup'

const {
    schema,
    // TODO: maybe add support for basic `reactive()` form data object
    data,
    onSubmit,
    initialData,
    resetOn,
    autoSubmitOn,
    validateOn = 'submit',
    notifyOnError,
} = defineProps<{
    /**
     * The zod schema to use for the form.
     * The schema is used to create the internal form data object to which the inputs are bound.
     * It is also used to validate the form data when the form is submitted.
     *
     * This is the primary way of defining the form structure.
     *
     * ---
     * ### Conditional Fields
     * Fields that are required / visible based on other flags are supported by using custom
     * utilities and for this to work, you need to have a computed property that returns the schema.
     * @example
     * const schema = computed(() => (
     *   z.object({
     *     test: z.string(),
     *     ...zRequiredIf({
     *       thisWillBeRequiredIfShouldRequireIsTrue: z.string(),
     *     }, shouldRequire.value),
     *   })
     * ))
     */
    schema: MaybeRef<ZodObject<T>>
    errors?: Partial<{ [key in keyof z.infer<ZodObject<T>>]: string }>
    // TODO: add support for non-zod type data
    data?: { [key: string]: string | number | boolean | null }
    onSubmit?: (data: z.infer<ZodObject<T>>) => void | Promise<void>
    /**
     * The initial data to use when the form is created. (NOT REACTIVE, so make sure that the data is already present
     * when the form component is being created)
     *
     * Sometimes, the data cannot be set directly as the default value in the schema,
     * so this prop can be used to set the initial data.
     * @default undefined
     */
    initialData?: MaybeRef<Partial<z.infer<ZodObject<T>>>>
    /**
     * When to reset the form.
     * Currently, it is used as 'boolean style' prop, but it could be extended to support more options in the future.
     * If set to `'submit'`, the form will fully reset its data after a successful submission.
     *
     * @default undefined
     */
    resetOn?: 'submit'
    /**
     * When to auto-submit the form.
     * Auto-submitting doesn't validate the form.
     *
     * _The recommended way it to use the `'form-change'` option, which submits the form when the form is blurred,
     * rather than on every input change._

     * By default, the form requires manual submission.
     * @default undefined
     */
    autoSubmitOn?: 'change' | 'change-debounced' | 'form-change'
    /**
     * When to validate the form.
     * By default, the form is validated only on explicit submit.
     * @default 'submit'
     */
    validateOn?: 'change' | 'form-change' | 'submit' | Array<'change' | 'form-change' | 'submit'>
    /**
     * Whether to show a notification when form errors occur.
     * By default, no notifications are shown.
     *
     * The possible values are:
     * - `'all'` - either the first validation error or the global error message will be shown in a notification
     * - `'validation'` - only the first validation error will be shown in a notification
     * - `'global'` - only the global error message will be shown in a notification
     * @default undefined
     */
    notifyOnError?: 'all' | 'validation' | 'global'
}>()

const schemaValue = toValue(schema)
watch(() => schema, (value) => {
    formData.value = updateFormDataObjectFromZodSchema(formData.value as FormDataObject<T>, value)
})

const formEl = ref<HTMLFormElement | null>(null)

if (autoSubmitOn === 'form-change') {   // temporary condition, adjust later
    // @ts-ignore
    const { focused } = useFocusWithin(formEl)

    /*
        TODO: improve logic for checking whether the form changed or not.
        Initial idea: save the state of the formData object when the form is focused
        and compare it to the state at focus out

        Currently, if for example the user were to change a toggle a checkbox twice
        (false -> true -> false) and then blurred the form, it would still count as
        a change and would auto-submit.
     */
    watch(focused, (newVal, oldVal) => {
        if (newVal || !oldVal) return
        // *-*- form blur event -*-*
        // TODO: maybe add function handlers?

        // skip if the form values didn't change
        if (!didFormChange.value) return

        // *-*- form change event -*-*
        // TODO: maybe add function handlers?

        if (autoSubmitOn === 'form-change') {
            handleSubmit('form-change')
        }
    })
}

const { t } = useI18n()

const { notifyError } = useNotifications()

const formData = ref<FormDataObject<T>>(getFormDataObjectFromZodSchema(schema, null, toValue(initialData)))  // TODO: try to switch to ref() later
const formErrors: Ref<FormErrors<T, string>> = ref({})
const globalFormErrorMessage = ref<string | null>(null)
const formId = useId()
const globalFormError = computed(() => ({
    id: formId,
    message: globalFormErrorMessage.value,
}))
const isFormSubmitting = ref<boolean>(false)
const getFieldValue = (field: keyof FormDataObject<T>) => (formData.value as FormDataObject<T>)[field]?.__v
const isFieldOfValue = (field: keyof FormDataObject<T>, value: any) => (formData.value as FormDataObject<T>)[field]?.__v === value

const bus = useEventBus<FormEvent>(Symbol('form-bus'))

/**
 * A flag used to determine whether the form values changed.
 * This is used when the form is set to auto-submit on form change (form blur).
 */
const didFormChange = ref<boolean>(false)

const debouncedHandleSubmit = useDebounceFn(handleSubmit, 150)

onMounted(() => {
    bus.on((event) => {
        if (event.type !== 'change') return

        didFormChange.value = true
        if (autoSubmitOn === 'change') {
            handleSubmit('change')
        } else if (autoSubmitOn === 'change-debounced') {
            debouncedHandleSubmit('change')
        }
    })
})

onUnmounted(() => {
    // clear all event bus listeners
    bus.reset()
})

function resetFormError(key: keyof z.infer<ZodObject<T>>) {
    // TODO: implement later if needed
    // TODO: fix type later
    // formErrors.value[key] = undefined
}

/**
 * Sets the validation errors for the form.
 * If done correctly, the errors will be automatically displayed below the matching inputs.
 *
 * The keys of the object should match the paths to the input value bindings.
 * @example
 * {
 *   'test': 'This is an error message',
 *   'nested.deep': 'This is an error message',
 * }
 *
 * // The above errors will be displayed below the following inputs:
 *
 * <CoreUiInput v-model:form="formData.test" />
 * <CoreUiInput v-model:form="formData.nested.deep" />
 *
 * @param errors The errors object to set (overwrites the previous errors)
 */
function setErrors(errors: FormErrors<T, string>) {
    formErrors.value = errors
}

/**
 * Returns the first validation error message (if any) from the form errors objects.
 * If specified, the global error message will be returned if no other validation errors are present.
 */
function getFirstValidationError(fallbackToGlobal: boolean = false, options?: Partial<{ includeFieldName: boolean }>): string | null {
    const validationError = options?.includeFieldName
        ? Object.entries(formErrors.value as Record<string, string>).find(([key, value]) => !!value)
        : Object.values(formErrors.value as Record<string, string>).find(Boolean)
    return validationError
        ? Array.isArray(validationError) ? `[${validationError[0]}]: ${validationError[1]}` : validationError
        : (fallbackToGlobal ? globalFormErrorMessage.value : null)
}

/**
 * Sets the global form error message.
 * This error message is available in the `globalFormError` computed property.
 * @param message The error message to set (if `null`, the error message will be cleared)
 */
function setGlobalError(message: string | null) {
    globalFormErrorMessage.value = message
}

/**
 * Returns the global form error message.
 */
function getGlobalError(): string | null {
    return globalFormErrorMessage.value
}

interface FormResetOptions {
    /**
     * Whether to reset the form data to empty values / zod schema defaults.
     * @default false
     */
    full: boolean
    /**
     * Whether to reset the form data taking the initial data into account.
     * @default false
     */
    data: boolean
}

/**
 * Resets the form to its initial state.
 * By default, the data is not reset - only the errors & other internal state.
 *
 * This can be overridden by using the `options` parameter.
 *
 * @param options The options to use when resetting the form
 */
function reset(options: Partial<FormResetOptions> = {}) {
    didFormChange.value = false
    if (options.data || options.full) {
        formData.value = getFormDataObjectFromZodSchema(schema, null, options.full ? null : toValue(initialData)) as UnwrapRef<FormDataObject<T>>
    }
    setErrors({} as FormErrors<T, string>)
    setGlobalError(null)
}

const beforeSubmitCallbacks = new Set<() => MaybePromise<boolean | void>>()
function registerCallback(event: 'before-submit', callback: () => MaybePromise<boolean | void>) {
    if (event === 'before-submit') {
        beforeSubmitCallbacks.add(callback)
    }
}

provide<CoreUiFormProvide<T, string>>(SymbolCoreUiForm, {
    formErrors: formErrors,
    resetFormError: resetFormError,
    registerCallback: registerCallback,
    bus: bus,
})

const emit = defineEmits<{
    /**
     * Emitted after the form submit request is resolved.
     */
    submitted: []
}>()

/**
 * Transforms the form data object used in custom value bindings to a plain object
 * that is sent with the request.
 * This function removes the `__v`, `__f` and `__r` properties from the object and
 * assigns raw values to the keys.
 *
 * @example
 * {
 *   test: {
 *     __v: 'value',
 *     __f: 'test',
 *     __r: false,
 *   },
 *   nested: {
 *     deep: {
 *       __v: 'value',
 *       __f: 'nested.deep',
 *       __r: false,
 *     },
 *   },
 * }
 *
 * // Transformed to:
 *
 * {
 *   test: 'value',
 *   nested: {
 *     deep: 'value',
 *   },
 * }
 *
 * @param data The custom form binding object to transform to a plain object
 */
function transformFormData(data: FormDataObject<T>) {
    return (Object.keys(data) as (keyof typeof data)[]).reduce((acc, key) => {
        const currentObject = data[key] /* as FormDataObject<T> */

        /*
            If the current object is an object, but not a value binding object,
            transform it recursively
            @example
            {
                nested: {  // <-- this is the plain object
                    test: {  // <-- this is the value binding object
                        __v: '',
                        __f: 'nested.test',
                        __r: false,
                    },
                },
            }
        */
        if (currentObject && currentObject.__v === undefined && currentObject.__f === undefined) {
            // @ts-expect-error -> TODO: fix types later
            acc[key] = transformFormData(currentObject)
            return acc
        }

        // @ts-expect-error -> TODO: fix types later
        acc[key] = currentObject?.__v
        return acc
    }, {}) as z.infer<typeof schemaValue>
}

function validateForm(transformedFormData: z.infer<typeof schemaValue>) {
    // TODO: Add support for async parsing when needed
    const result = toValue(schema).safeParse(transformedFormData)

    if (!result.success) {
        const errors = {} as FormErrors<T, string>
        for (const issue of result.error.issues) {
            let path = ''
            for (const key of issue.path) {
                path += `${path.length ? '.' : ''}${key}`
            }

            // @ts-ignore -> TODO: fix types later
            if (errors[path]) continue
            // @ts-ignore -> TODO: fix types later
            errors[path] = issue.message
        }

        setErrors(errors)

        if (import.meta.dev) {
            console.warn('[CoreUiForm]: The form has validation errors and wasn\'t submitted', errors)
        }

        // error notifications
        if (notifyOnError === 'validation' || notifyOnError === 'all') {
            const message = getFirstValidationError()
            if (message) notifyError(message)
        }

        return false
    } else {
        setErrors({} as FormErrors<T, string>)
    }

    return true
}

async function handleSubmit(origin: 'submit' | 'change' | 'form-change' = 'submit') {
    // TODO: fix type
    const transformedData = transformFormData(formData.value as FormDataObject<T>)

    if (Array.isArray(validateOn) ? validateOn.includes(origin) : validateOn === origin) {
        const result = await validateForm(transformedData)
        if (!result) return
    }

    // call the beforeSubmitCallbacks
    const results = await Promise.allSettled([...beforeSubmitCallbacks].map((callback) => callback()))
    if (results.some((result) => result.status === 'rejected')) return
    if (results.some((result) => result.status === 'fulfilled' && result.value === false)) return

    // call the onSubmit callback
    if (onSubmit) {
        const maybePromise = onSubmit(transformedData)

        // await the result of the onSubmit() function, if it returns a Promise to set the loading state
        if (!(maybePromise instanceof Promise)) return

        isFormSubmitting.value = true

        try {
            await maybePromise
            // only reset the form after explicit submit (not after auto-submit)
            if (origin === 'submit') {
                // reset after a successful submit
                reset({ full: resetOn === 'submit' })
            }
        } catch (e) {
            if (e instanceof ApiResponseError) {
                // @ts-expect-error // TODO
                setErrors(e.getValidationErrorsByField())
            } else {
                let wasErrorSet = false
                // temporary handling of a special case ($fetch login)
                // TODO: remove after login logic is refactored

                // TODO: rewrite to use instanceof when the following issue is fixed:
                // https://github.com/nuxt/nuxt/issues/25747
                if (typeof e === 'object' && e !== null && 'data' in e && typeof e.data === 'object') {
                    // @ts-ignore   TODO: remove when using instanceof
                    if (e.data?.data?.validationErrorsByField) {
                        // @ts-ignore
                        setErrors(e.data.data.validationErrorsByField)
                        wasErrorSet = true
                    }
                    // @ts-ignore   TODO: remove when using instanceof
                    if (e.data?.message) {
                        // @ts-ignore   TODO: remove when using instanceof
                        setGlobalError(e.data.message)
                        wasErrorSet = true
                    }
                }

                // global error message
                if (!wasErrorSet) {
                    setGlobalError(t('_core_simploshop.labels.unknownError'))
                    console.error(e)
                }
            }

            // error notifications
            if (notifyOnError) {
                const message = notifyOnError !== 'global'
                    ? getFirstValidationError(notifyOnError === 'all', { includeFieldName: true })
                    : getGlobalError()
                if (message) notifyError(message)
            }

        } finally {
            isFormSubmitting.value = false
        }

    } else {
        // only reset the form after explicit submit (not after auto-submit)
        if (origin === 'submit') {
            // reset after a submit without an onSubmit callback
            reset({ full: resetOn === 'submit' })
        }
    }

    emit('submitted')
}

onUnmounted(() => {
    beforeSubmitCallbacks.clear()
})

async function submit() {
    // call the native HTML form validator
    if (formEl.value?.reportValidity() === false) {
        return false
    }
    await handleSubmit()
    return true
}

defineExpose({
    setErrors,
    setGlobalError,
    reset,
    submit,
})

</script>

<style lang="scss" scoped>

// wrapped in last-child to only disable the margin at the end of all forms
.sim-form:last-child {
    :slotted(.sim-form-row) {
        &:last-child {
            margin-bottom: 0;
        }
    }

    :slotted(.sim-form-section) {
        &:last-child {
            .sim-form-row:last-child {
                margin-bottom: 0;
            }
        }
    }
}

</style>
